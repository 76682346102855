<template>
	<div class="container-fluid">
        <LoadingSpinner v-if="loading" />
        <div v-else id="marelist">
        	<div class="row mares">
				<div class="col-2">{{ $t('mouvement.mouvement_date') }}</div>
				<div class="col-2">{{ $t('mouvement.mouvement_label') }}</div>
				<div class="col-2">{{ $t('mouvement.mouvement_adresse') }}</div>
				<div class="col-2">{{ $t('mouvement.mouvement_cp') }}</div>
				<div class="col-2">{{ $t('mouvement.mouvement_ville') }}</div>
				<div class="col-2">{{ $t('mouvement.mouvement_raison') }}</div>
        	</div>
			<div class="row mares mt-1" v-for="mouvement in mouvements" :key="mouvement.mouvement_id">
				<div class="col-2">
					<font-awesome-icon style="color: green;" v-if="mouvement.mouvement_type == 1" :icon="['fal', 'sign-in-alt']" />
					<font-awesome-icon style="color: blue;" v-else-if="mouvement.mouvement_type == 5" :icon="['fal', 'farm']" />
					<font-awesome-icon style="color: red;" v-else :icon="['fal', 'sign-out-alt']" />
					{{ formatDate(mouvement.mouvement_date) }}
				</div>
				<div class="col-2">{{ mouvement.mouvement_label }}</div>
				<div class="col-2">{{ mouvement.mouvement_adresse }}</div>
				<div class="col-2">{{ mouvement.mouvement_cp }}</div>
				<div class="col-2">{{ mouvement.mouvement_ville }}</div>
				<div class="col-2">{{ mouvement.mouvement_raison }}</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Horse from '@/mixins/Horse.js'

	export default {
		name: 'Mouvements',
		mixins: [Horse],
		props: ['horse_id'],
		data () {
			return {
				loading: false,
				mouvements: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.mouvements = await this.getHorseMouvements(this.horse_id)
				this.loading = false
			},
		},

		components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>